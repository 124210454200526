<!--
 * @Author: your name
 * @Date: 2021-10-14 15:00:42
 * @LastEditTime: 2021-11-16 10:10:28
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \mingtai-yonghu\src\view\index\index.vue
-->
<template>
  <div class="container">
    <div class="topBox">
      <img class="leftImg" src="../../static/images/mingtai/topTxt.png" />
      <img class="rightImg" src="../../static/images/mingtai/loginRight.png" />
    </div>
    <div class="centBox">
      <div class="loginBox">
        <div class="boxs">
          <img
            class="loginYun"
            src="../../static/images/mingtai/loginYun.png"
          />
          <div class="topTxt">
            <p class="big">洛阳明泰健康咨询有限公司</p>
            <p />
            <p>www.lysyrk.com</p>
            <p />
          </div>
          <div class="splitBox">
            <div class="borderBox"></div>
            <div class="tipTxt">账号登录</div>
            <div class="borderBox"></div>
          </div>
          <div class="inpBox">
            <input
              type="text"
              v-model="userObj.account"
              placeholder="用户名/邮箱/手机"
            />
          </div>
          <div class="inpBox">
            <input
              type="password"
              v-model="userObj.password"
              placeholder="密码"
            />
          </div>
          <div class="btnBox">
            <button @click="toLogin" :disabled="loginDisabled">登录</button>
          </div>
          <div class="botTxt">
            <div class="wjpsd" @click="() => {forgetVis = true}">忘记密码？</div>
            <div class="reg" @click="$router.push({name:'Reg'})">注册</div>
          </div>
        </div>
      </div>
    </div>
    <div class="botBox">
      <div class="footerLink">
        <div class="leftIcon">
          <img src="../../static/images/mingtai/botLogo.png" />
        </div>
        <div class="rightList">
          <div class="linkTitle">艾灸须知</div>
          <div class="linkBox">
            <div v-for="(item,index) in freeArticleList" :key="index">
              <router-link :to="'/FreeAticle?id='+item.id">{{index+1}}.{{item.title}}</router-link>
            </div>
          </div>
        </div>
      </div>

      <a-modal
        title="忘记密码"
        :width="400"
        :visible="forgetVis"
        @cancel="handleCancel"
        :footer="null"
      >
        <a-form
          :form="form"
          :label-col="{ span: 5 }"
          :wrapper-col="{ span: 19 }"
          @submit="handleSubmit"
        >
          <a-form-item label="手机号">
            <a-input v-decorator="['phone',{rules: [{ required: true, message: '请输入手机号！' },{pattern:/^1[3456789]\d{9}$/,message:'请输入正确的手机号' }]}]" />
          </a-form-item>
          <a-form-item label="验证码">
            <a-input-search v-decorator="['code',{rules: [{ required: true, message: '请输入验证码' }],},]" @search="getCode">
              <a-button slot="enterButton" :disabled="codeBtnDisabled() || isSending">
                {{codeTxt}}
              </a-button>
            </a-input-search>
          </a-form-item>
          <a-form-item label="密码">
            <a-input-password v-decorator="['password', {rules: [{required: true,  message: '请输入密码！'},{pattern:/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[^]{8,16}$/,message:'至少8位数字且包含大小写字母及数字'}]}]" />
          </a-form-item>
          <a-form-item :wrapper-col="{ span: 24, offset: 0 }">
            <a-button type="primary" class="defBut" html-type="submit">
              确定
            </a-button>
          </a-form-item>
        </a-form>
      </a-modal>
      <s-footer />
    </div>
  </div>
</template>

<script>
import { timeFix } from '@/util/util'
import {Modal,notification} from 'ant-design-vue'///es/notification
import { mapActions } from 'vuex'
import { Base64 } from "@/util/util";
import sFooter from "@/view/components/Footer";

var base64 = new Base64();
export default {
  components: {
    sFooter,
  },
  data() {
    return {
      userObj: {
        account: null,
        password: null,
      },
      loginDisabled:false,
      isLoginError:false,
      forgetVis:false,
      codeTxt: "发送验证码",
      count: 60,
      form: this.$form.createForm(this),
      isSending: false,
      freeArticleList: [],
    };
  },
  created() {
    //获取免费文章
    this.$api.getFreeArticlePageList({currentNo:1,pageSize:24},null).then(res => {
      this.freeArticleList = res.data.rows
    })
  },
  methods: {
    ...mapActions(['Login']),

    codeBtnDisabled(){
      let result = true
      this.form.validateFields(['phone'],(err,values) => {
        if (!err && values.phone) {
          result = false
        }
      })
      return result
    },
    getCode() {
      if(this.timer){
        clearInterval(this.timer);
      }
      const TIME_COUNT = 60;
      let phone = this.form.getFieldValue('phone')
      this.$api.sendSmsCode({phoneNumbers:phone},null).then(res => {
        if(res.success){
          this.$message.success('短信发送成功！')
          this.isSending = true
          this.timer = setInterval(() => {//开启倒计时
            if (this.count > 1 && this.count <= TIME_COUNT) {
              this.count -= 1;
              this.codeTxt = this.count+'秒后重发';
            } else {//倒计时结束
              clearInterval(this.timer);
              this.isSending = false
              this.codeTxt = "再次发送";
              this.count = 60;
            }
          }, 1000);
        }else{
          this.$message.error(res.message)
        }
      })
    },
    //忘记密码提交
    handleSubmit() {
      const { form: { validateFields } } = this
      validateFields((err,values) => {
        if(!err){
          this.$api.forgetPwd(null,values).then(res => {
            if(res.success){
              this.$message.success('修改成功！')
              this.forgetVis = false
            }else{
              this.$message.error(res.message)
            }
          })
        }
      })
    },
    handleCancel(){
      this.forgetVis = false
      this.isSending = false
      this.codeTxt = '发送验证码'
    },

    toLogin() {
        this.loginDisabled = true
        this.Login(this.userObj)
        .then((res) => this.loginSuccess(res))
        .catch(err => this.requestFailed(err))
        .finally(() => {
            this.loginDisabled = false
        })//审核成功10110011审核失败10110012
    },
    loginSuccess (res) {
      this.isLoginError = false
      if(this.$route.query.redirect){
        this.$router.push({path: this.$route.query.redirect})
      }else{
        this.$router.push({ name: 'Home' })
      }
      // 延迟 1 秒显示欢迎信息
      setTimeout(() => {
        notification.success({
          message: '欢迎',
          description: `${timeFix()}，欢迎回来`
        })
      }, 1000)
    },
    requestFailed (err) {
      if(err.code==10110011 || err.code==12){
        let params = null
        let query = {message:err.message,data:err.data}
        if(err.code==12){
          query = {message:'审核未通过',data:err.message}
          params = this.userObj
        }
        this.$router.push({name:'Check',query:query,params:params})
      }else{
        this.accountLoginErrMsg = err
        this.isLoginError = true
      }
    },
  },
};
</script>

<style lang="scss" scoped>
// 公共样式
.hide {
  display: none;
}
.show {
  display: block;
}
a {
  color: #656565;
}
.defBut {
  width: 100%;
  font-family: 微软雅黑;
  background-color: #063a61;
  height: 35px;
  color: white;
  border: 0px;
  font-size: 14px;
  cursor: pointer;
  margin-bottom: 5px;
}
.container {
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: relative;
  text-align: center;
  font-size: 0;
  .topBox {
    height: 110px;
    display: flex;
    box-sizing: border-box;
    padding: 0px 100px;
    align-items: flex-end;
    position: relative;
    width: 100%;
    .leftImg {
      width: 350px;
      height: 80px;
    }
    .rightImg {
      width: 450px;
      height: 40px;
      position: absolute;
      right: 100px;
      top: 50%;
      margin-top: -10px;
    }
  }
  .centBox {
    height: calc(100% - 300px);
    background-image: url("../../static/images/mingtai/loginBg.png");
    background-size: 100% 100%;
    position: relative;
    display: flex;
    align-items: center;
    .loginBox {
      position: absolute;
      background-color: white;
      padding: 5px;
      width: 350px;
      height: 370px;
      border: 8px solid #af9e80;
      box-sizing: content-box;
      left: 50%;
      top: 50%;
      margin-top: -205px;
      .boxs {
        width: 100%;
        height: 100%;
        border: 4px solid #af9e80;
        box-sizing: border-box;
        padding: 15px 25px;
        .topTxt {
          p {
            color: #b2a285;
            font-size: 18px;
          }
          .big {
            font-size: 24px;
            font-weight: bold;
          }
        }
        .splitBox {
          display: flex;
          font-size: 14px;
          color: #b3b3b3;
          margin-top: 10px;
          align-items: center;
          .tipTxt {
            margin: 0px 10px;
            font-size: 16px;
          }
          .borderBox {
            flex: 1;
            border-bottom: 2px dotted #bababa;
          }
        }
        .inpBox {
          margin-top: 10px;
          input {
            border: 1px solid #e6e6e6;
            font-size: 16px;
            padding: 10px;
            color: #b2a285;
            width: 100%;
            font-family: 微软雅黑;
          }
        }
        .btnBox {
          margin-top: 10px;
          button {
            width: 100%;
            background-color: #af9e80;
            height: 45px;
            color: white;
            border: 0px;
            font-size: 18px;
            cursor: pointer;
          }
        }
        .botTxt {
          margin-top: 10px;
          display: flex;
          color: #af9e80;
          font-size: 14px;
          div {
            cursor: pointer;
            text-align: left;
            flex: 2;
          }
          div:last-child {
            text-align: right;
          }
        }
      }
      .loginYun {
        position: absolute;
        width: 120px;
        left: -50px;
        bottom: -10px;
      }
    }
  }
  .botBox {
    height: 161px;
    background-color: #063a61;
    position: absolute;
    width: 100%;
    margin-top: -11px;
    .footerLink {
      display: flex;
      align-items: center;
      width: 100%;
      height: 100%;
      padding-left: 500px;
      box-sizing: border-box;
      .leftIcon {
        img {
          width: 90px;
        }
      }
      .rightList {
        margin-left: 40px;
        .linkTitle {
          color: white;
          font-size: 22px;
          font-weight: bold;
          text-align: left;
        }
        .linkBox {
          display: flex;
          flex-direction: column;
          flex-wrap: wrap;
          width: 300px;
          height: 70px;
          text-align: left;
          div {
            flex: 33%;
            height: 20px;
            font-size: 14px;
            a {
              color: #d2dbe2;
            }
            color: #d2dbe2;
          }
        }
      }
    }
  }
}
</style>